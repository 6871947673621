@tailwind base;
@tailwind components;
@tailwind utilities;

*body {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    display: none;
}

.table_container {
    min-height: calc(100vh - theme('spacing.36'));
}

input[type=range] {
    // overflow: hidden;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;

    &::-webkit-slider-runnable-track {
        height: 15px;
        -webkit-appearance: none;
        appearance: none;
        color: #00B5EF;
        background: #2BEA13;
        overflow: hidden;
        border-radius: 8px;
    }

    &::-webkit-slider-thumb {
        width: 16px;
        -webkit-appearance: none;
        appearance: none;
        height: 15px;
        margin-top: 0;
        cursor: pointer;
        background: #2BEA13;
        box-shadow: -905px 0 0 900px #00B5EF;
        border: 3px solid #fff;
        border-radius: 100%;
    }

    &::-moz-range-progress {
        background-color: #00B5EF;
        border: 3px solid #00B5EF;
    }

    &::-moz-range-track {
        background-color: #2BEA13;
        border: 3px solid #2BEA13;
    }

    &::-ms-fill-lower {
        background-color: #00B5EF;
    }

    &::-ms-fill-upper {
        background-color: #2BEA13;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}